import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Delete, Add } from "@material-ui/icons";
import { isFunction } from "lodash";
import { pullAt, assign, set } from "lodash/fp";
import { MealData } from "../features/meals/dbHook";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}));

function Ingredient({ index, ingredient, editing, setEditing, onSave }) {
  if (editing?.index === index) {
    return (
      <TextField
        autoFocus
        value={editing.ingredient}
        onChange={(event) =>
          setEditing({ index, ingredient: event.target.value })
        }
        onBlur={onSave}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            onSave();
          } else if (event.keyCode === 27) {
            setEditing(null);
            event.preventDefault();
            return false;
          }
        }}
      />
    );
  }

  return <ListItemText primary={ingredient} />;
}

export function MealPopover({ meal: defaultMealProp, onClose, ...extraProps }) {
  const classes = useStyles();
  const [defaultMeal, setDefaultMeal] = useState(defaultMealProp);
  const [meal, setMeal] = useState(defaultMeal);
  const [ingredientToAdd, setIngredientToAdd] = useState("");
  const [editing, setEditing] = useState(null);

  function updateIngredients(f) {
    setMeal(assign(meal, { ingredients: f(meal.ingredients) }));
  }

  function addIngredient() {
    updateIngredients((ingredients) => [...ingredients, ingredientToAdd]);
    setIngredientToAdd("");
  }

  function removeIngredient(index) {
    updateIngredients((ingredients) => pullAt(index, ingredients));
  }

  function save() {
    if (editing !== null) {
      return false;
    }
    if (isFunction(onClose)) {
      onClose(meal);
    }
  }

  if (defaultMeal !== defaultMealProp) {
    setDefaultMeal(defaultMealProp);
    setMeal(defaultMealProp);
  }

  if (!extraProps.hasOwnProperty("open")) {
    extraProps.open = extraProps.anchorEl !== null;
  }
  return (
    <Popover onClose={save} {...extraProps}>
      <div className={classes.container}>
        <Typography variant="h6">{meal.name}</Typography>
        <List>
          {meal.ingredients.map((ingredient, index) => (
            <ListItem
              key={index}
              button
              onClick={() => setEditing({ index, ingredient })}
            >
              <Ingredient
                onSave={() => {
                  updateIngredients((ingredients) =>
                    set(editing.index, editing.ingredient, ingredients)
                  );
                  setEditing(null);
                }}
                {...{ index, ingredient, editing, setEditing }}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => removeIngredient(index)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <ListItem key="append">
            <TextField
              value={ingredientToAdd}
              onChange={(event) => setIngredientToAdd(event.target.value)}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  addIngredient();
                }
              }}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={addIngredient}>
                <Add />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    </Popover>
  );
}

MealPopover.props = {
  meal: PropTypes.instanceOf(MealData).isRequired,
  onClose: PropTypes.func.isRequired,
};
