import React, { Fragment, useState } from "react";
import { isLoaded } from "react-redux-firebase";
import { TextField, IconButton, Typography } from "@material-ui/core";
import { Details } from "@material-ui/icons";
import { useDrag, useDrop } from "react-dnd";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import classNames from "classnames";

import { useMealDB, useMealDBConnect } from "../features/meals/dbHook";
import { MealPopover } from "./MealPopover";

const Text = styled.div`
  display: inline-block;
  width: 100%;
  padding: 3px;
  margin: 0;
  min-height: 100%;
  &.loaded {
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 229, 100, 0.3);
    }
  }
`;

export default function Meal({ date, type }) {
  const [editing, setEditing] = useState(null);
  const [detailsAnchor, setDetailsAnchor] = useState(null);
  const db = useMealDB();
  const meal = useMealDBConnect(date, type);

  const [, drag] = useDrag({
    item: { type: "meal.swap", date, mealType: type, meal: meal },
  });

  const [{ dropHoverSwap }, dropSwap] = useDrop({
    accept: "meal.swap",
    drop(item) {
      db.update(item.date, item.mealType, meal);
      db.update(date, type, item.meal);
    },
    collect: (monitor) => ({
      dropHoverSwap: monitor.isOver(),
    }),
  });
  const [{ dropHoverOverwrite }, dropOverwrite] = useDrop({
    accept: "meal.overwrite",
    drop(item) {
      db.update(date, type, item.meal);
    },
    collect: (monitor) => ({
      dropHoverOverwrite: monitor.isOver(),
    }),
  });

  const dropHover = dropHoverSwap || dropHoverOverwrite;

  if (!isLoaded(meal)) {
    return <Text>Loading...</Text>;
  }

  function save() {
    db.update(date, type, { name: editing });
    setEditing(null);
  }

  function cancel() {
    setEditing(null);
  }

  if (editing === null) {
    const extraProps = dropHover
      ? { style: { backgroundColor: "rgba(255, 229, 100, 0.3)" } }
      : {};

    return (
      <div ref={dropOverwrite} {...extraProps}>
        <div ref={dropSwap}>
          <div ref={drag}>
            <Text
              onClick={() => setEditing(meal.name)}
              className={classNames({ loaded: isLoaded(meal) })}
            >
              <Typography>{meal.name || "\u00A0"}</Typography>
            </Text>
            {meal.name && (
              <Fragment>
                <IconButton
                  size="small"
                  onClick={(event) => setDetailsAnchor(event.target)}
                  disableRipple
                >
                  <Details />
                </IconButton>
                <MealPopover
                  meal={meal}
                  onClose={(meal) => {
                    setDetailsAnchor(null);
                    db.update(date, type, meal);
                  }}
                  anchorEl={detailsAnchor}
                />
                <Typography variant="caption">
                  {meal.ingredients.join(", ")}
                </Typography>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <TextField
      multiline
      value={editing}
      onChange={(event) => setEditing(event.target.value)}
      onBlur={save}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          cancel();
        } else if (event.keyCode === 13) {
          save();
        }
      }}
      autoFocus
    />
  );
}

Meal.propTypes = {
  date: PropTypes.instanceOf(moment).isRequired,
  type: PropTypes.oneOf(["lunch", "dinner"]).isRequired,
};
