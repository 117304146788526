// React
import React from "react";
import ReactDOM from "react-dom";
// Redux
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
// Firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// Material-UI
import "typeface-roboto";
// React + Redux + Firebase
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

// App
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./reducers";

const store = configureStore({ reducer: rootReducer });

fetch("/__/firebase/init.json").then(async (response) => {
  const firebaseConfig = await response.json();
  //apiData.authDomain = "food.abesto.net";
  firebase.initializeApp(firebaseConfig);

  const firestore = firebase.firestore();
  if (window.location.hostname === "localhost") {
    firestore.settings({ host: "localhost:8080", ssl: false });
  }
  firestore.enablePersistence();

  ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        config={{}}
        firebase={firebase}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
