import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { DndProvider } from "react-dnd";
import DndBackend from "react-dnd-html5-backend";
import { merge } from "lodash";
import Holidays from "date-holidays";
import moment from "moment";
import styled from "styled-components";

import {
  Chip,
  Table,
  Divider,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ButtonGroup,
  Button,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDrag, useDrop } from "react-dnd";

import {
  previousWeek,
  nextWeek,
  resetWeek,
  setWeek,
  selectStartDay,
} from "../features/meals/startWeekSlice";
import { useMealDB, MealData } from "../features/meals/dbHook";
import Meal from "./Meal";

const holidays = new Holidays("GB");

function daysToShow(start) {
  const data = [];
  for (var i = 0; i < 3 * 7; i++) {
    data.push(start.clone().add({ days: i }));
  }
  return data;
}

const recipes = {
  "Minden heten": ["Halat", "Rantotta", "Resztelt maj", "Risotto"],
  Rendelos: ["Burger", "Palacsinta", "Pho", "Poke", "Sushi"],
  Gyors: ["Chilli con carne", "Mikrozos", "Stir fry", "Szarvasragu", "Vadas"],
  Lassu: [
    "Kacsacomb",
    "Mustaros karaj",
    "Puffancs",
    "Tárkonyos csirkeragu",
    "Tray bake",
  ],
  Mindenmas: [
    "Csirkecomb",
    "Csirkemell",
    "Csirkemelles rizses szoszos",
    "Curry",
    "Darálthús",
    "Egyéb állat ragu / pörkölt",
    "Gombapörkölt baconnal",
    "Húsgombóc",
    "Ham&Eggs",
    "Hentestokány",
    "Jacket Potato",
    "Kolbász + Krumplipüré + zöldség",
    "Kolbász + Vajaskrumpli + zöldség",
    "Mindenes saláta",
    "Piritottrizses",
    "Raguleves",
    "Sushiburrito",
    "Zöldségpörkölt",
  ],
};

Object.keys(recipes).map(
  (section) =>
    (recipes[section] = recipes[section].map((meal) => new MealData(meal)))
);

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3px;
  & > * {
    margin: 5px;
  }
`;

function RecipeChip({ recipe }) {
  const [, dragRecipe] = useDrag({
    item: {
      type: "meal.overwrite",
      meal: recipe,
    },
  });
  return <Chip ref={dragRecipe} label={recipe?.name ?? recipe} clickable />;
}

function MealTrash({ children }) {
  const db = useMealDB();
  const [, dropMeal] = useDrop({
    accept: "meal.swap",
    drop(item) {
      db.clear(item.date, item.mealType);
    },
  });

  return <div ref={dropMeal}>{children}</div>;
}

export default function Meals() {
  const dispatch = useDispatch();
  const monday = useSelector(selectStartDay);
  const now = moment();

  return (
    <DndProvider backend={DndBackend}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <ButtonGroup>
              <Button
                variant="outlined"
                onClick={() => dispatch(previousWeek())}
              >
                Prev
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => dispatch(resetWeek())}
              >
                Jump to Today
              </Button>
              <Button variant="outlined" onClick={() => dispatch(nextWeek())}>
                Next
              </Button>
            </ButtonGroup>
            <TextField
              style={{ marginLeft: "15px" }}
              type="date"
              value={monday.format("YYYY-MM-DD")}
              onChange={(event) => dispatch(setWeek(event.target.value))}
            />
          </Paper>
        </Grid>

        <Grid item xs={8}>
          <TableContainer component={Paper}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "7%" }}>Date</TableCell>
                  <TableCell style={{ width: "7%" }}>Day</TableCell>
                  <TableCell style={{ width: "15%" }}>Lunch</TableCell>
                  <TableCell style={{ width: "15%" }}>Dinner</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {daysToShow(monday).map((date) => {
                  const allProps = { style: {} };
                  const dateProps = { style: {} };
                  // TODO no black border bottom if this is the last row
                  if (date.day() === 0) {
                    allProps.style.borderBottomColor = "black";
                  }
                  if (
                    holidays.isHoliday(date.toDate()) ||
                    date.day() === 0 ||
                    date.day() === 6
                  ) {
                    dateProps.style.color = "darkRed";
                  }
                  if (date.isSame(now, "day")) {
                    allProps.style.borderBottom = "2px solid red";
                    allProps.style.borderTop = "2px solid red";
                  }
                  merge(dateProps, allProps);
                  return (
                    <TableRow key={date} hover>
                      <TableCell {...dateProps}>
                        <Typography>{date.format("YYYY-MM-DD")}</Typography>
                      </TableCell>
                      <TableCell {...dateProps}>
                        <Typography>{date.format("dddd")}</Typography>
                      </TableCell>
                      <TableCell {...allProps}>
                        <Meal date={date} type="lunch" />
                      </TableCell>
                      <TableCell {...allProps}>
                        <Meal date={date} type="dinner" />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={4}>
          <MealTrash>
            <Paper style={{ padding: "5px" }}>
              {Object.keys(recipes).map((category) => (
                <div style={{ margin: "5px" }} key={category}>
                  <Typography variant="h6">{category}</Typography>
                  <ChipContainer>
                    {recipes[category].map((recipe) => (
                      <RecipeChip recipe={recipe} key={recipe.name} />
                    ))}
                  </ChipContainer>
                  <Divider />
                </div>
              ))}
            </Paper>
          </MealTrash>
        </Grid>
      </Grid>
    </DndProvider>
  );
}
