import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { isString, toPlainObject } from "lodash";

const collection = "meals";

function dateToDoc(date) {
  return date.clone().day("Monday").format("YYYY-MM-DD");
}

function dateToKey(date) {
  return date.format("YYYY-MM-DD");
}

export class MealData {
  constructor(nameOrData) {
    if (isString(nameOrData)) {
      this.name = nameOrData;
      this.ingredients = [];
    } else {
      this.name = nameOrData?.name ?? "";
      this.ingredients = nameOrData?.ingredients ?? [];
    }
  }

  get isLoaded() {
    return true;
  }
}

export function useMealDB() {
  const firestore = useFirestore();

  function update(date, type, meal) {
    firestore.set(
      { collection, doc: dateToDoc(date) },
      { [dateToKey(date)]: { [type]: toPlainObject(meal) } },
      { merge: true }
    );
  }

  function clear(date, type) {
    update(date, type, null);
  }

  return { update, clear };
}

export function useMealDBConnect(date, type) {
  const [meal, setMeal] = useState();
  useFirestoreConnect({ collection, doc: dateToDoc(date) });
  const weekMeals = useSelector(
    ({ firestore: { data } }) => data.meals?.[dateToDoc(date)]
  );

  useEffect(() => {
    if (isLoaded(weekMeals)) {
      setMeal(new MealData(weekMeals?.[dateToKey(date)]?.[type]));
    }
  }, [weekMeals, date, type]);

  return meal;
}
