import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const FORMAT = "YYYY-MM-DD";

const startWeekSlice = createSlice({
  name: "startWeek",
  initialState: {
    monday: moment().startOf("isoWeek").format(FORMAT),
  },
  reducers: {
    previousWeek(state) {
      state.monday = moment(state.monday, FORMAT)
        .subtract({ isoWeeks: 1 })
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
    },
    nextWeek(state) {
      state.monday = moment(state.monday, FORMAT)
        .add({ isoWeeks: 1 })
        .startOf("isoWeek")
        .format(FORMAT);
    },
    setWeek: {
      reducer(state, action) {
        state.monday = moment(action.payload, FORMAT)
          .startOf("isoWeek")
          .format(FORMAT);
      },
      prepare(dateStr) {
        return { payload: dateStr };
      },
    },
    resetWeek(state) {
      state.monday = moment().startOf("isoWeek").format(FORMAT);
    },
  },
});

export const {
  nextWeek,
  previousWeek,
  resetWeek,
  setWeek,
} = startWeekSlice.actions;

export function selectStartDay(state) {
  return moment(state.startWeek.monday, FORMAT);
}

export default startWeekSlice.reducer;
