import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isLoaded, isEmpty, useFirebase } from "react-redux-firebase";
import GoogleButton from "react-google-button";

const LoginRequired = ({ auth, children }) => {
  const firebase = useFirebase();
  const loginWithGoogle = () => firebase.login({ provider: "google" });

  if (!isLoaded(auth)) {
    return <p>Loading authentication...</p>;
  }
  if (isEmpty(auth)) {
    return <GoogleButton onClick={loginWithGoogle} />;
  }
  return children;
};

LoginRequired.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ firebase: { auth } }) => ({ auth });

export default connect(mapStateToProps)(LoginRequired);
