import React from "react";
import "firebase/auth";

import { AppBar, Tabs, Tab, Container } from "@material-ui/core";

import Meals from "../components/Meals";

function Home() {
  return (
    <Container maxWidth="xl">
      <AppBar position="static">
        <Tabs variant="fullWidth" value={0}>
          <Tab label="Meals" />
        </Tabs>
      </AppBar>
      <main>
        <Meals />
      </main>
    </Container>
  );
}

export default Home;
