import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import CssBaseline from "@material-ui/core/CssBaseline";

import Home from "./pages/Home";
import LoginRequired from "./containers/LoginRequired";

function App() {
  return (
    <Fragment>
      <CssBaseline />
      <Offline polling={{url: "https://food.abesto.net"}}>
        <h1>Network connection lost...</h1>
      </Offline>
      <Online polling={{url: "https://food.abesto.net"}}>
        <LoginRequired>
          <Router>
            <Switch>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Router>
        </LoginRequired>
      </Online>
    </Fragment>
  );
}

export default App;
